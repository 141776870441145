<template>
  <IonApp>
    <IonSplitPane content-id="main-content" when="md">
      <ion-menu content-id="main-content" type="overlay" :style="$route.path == '/login' ? 'display:none' : '' || $route.path == '/folder/idoneity' ? 'display:none' : ''">
        <ion-content>
          <ion-list id="inbox-list">
            <div v-if="user.company=='ABJ SISTEMI'">
              <img style="width:50%;height:50%" src="images\abj_logo_tondo.JPG">
            </div>
            <div v-if="user.company=='CHP-BLU'">
              <img style="width:50%;height:50%" src="images\logo_cophis.JPG">
            </div>
            <div v-if="user.company=='GATE ENERGY'">
              <img style="width:50%;height:50%" src="images\logo_gate.JPG">
            </div>
            <img style="width:50%;height:50%" src="images\logo_hbj_tondo.JPG"><br>
            <ion-note style="font-size:18px;">Ciao, {{ $store.state.userName }} </ion-note>          
            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">  
            <!--CONSULENTE-->
              <div v-if="$store.state.userRole==1">
                <div v-if="p.title!='Badge Virtuale' && p.title!='Report presenze' && p.title!='Gestione operativa' && p.title!='Report idoneità' && p.title!='Gestione MES'">
                  <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                    <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                    <ion-label>{{ p.title }}</ion-label>
                  </ion-item>
                </div>
              </div>
            <!--STAGISTA/INTERINALE-->
              <div v-if="$store.state.userRole==2 || $store.state.userRole==3">
                <div id="menu" v-if="p.title=='Badge Virtuale' || p.title=='Report presenze' || p.title=='Dashboard' || p.title=='Monitoraggio MES' || p.title=='Gestione area personale' || p.title=='Programmazione attività' || p.title=='Richiesta permessi' || p.title=='Report permessi'">
                  <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                    <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                    <ion-label>{{ p.title }}</ion-label>
                  </ion-item>
                </div>
              </div>
            <!--DIPENDENTE-->
              <div v-if="$store.state.userRole==4">
                <div v-if="p.title!='Report idoneità' || p.title!='Storico MES' || p.title!='Gestione MES'">
                  <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                    <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                    <ion-label>{{ p.title }}</ion-label>
                  </ion-item>   
                </div>             
              </div>
            <!--ADMIN-->
              <div v-if="$store.state.userRole>4 && $store.state.userRole!=7">
                <div v-if="p.title!='Badge Virtuale' && p.title!='Report presenze' && p.title!='Report idoneità' || p.title!='Gestione MES'">
                  <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                    <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                    <ion-label>{{ p.title }}</ion-label>
                  </ion-item>
                </div>
              </div>
            <!--SUPERADMIN-->
              <div v-if="$store.state.userRole==7">
                <div v-if="p.title!='Badge Virtuale' && p.title!='Report presenze'">
                <!--div-->
                  <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                    <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                    <ion-label>{{ p.title }}</ion-label>
                  </ion-item>
                </div>
              </div>
            </ion-menu-toggle>        
          </ion-list>
          <div id="logoutDiv">
            <ion-button expand="block" id="logout" @click="logOut">LOGOUT</ion-button>
          </div>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script>
  import { 
    IonApp, 
    IonButton, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList,  
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane 
  } from '@ionic/vue';
  import { defineComponent, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { airplaneOutline, archiveOutline, archiveSharp, barChartOutline, bookmarkOutline, bookmarkSharp, calendarNumberOutline, cogOutline, documentTextOutline, earthOutline, fileTrayOutline, heartOutline, heartSharp, homeOutline, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, personCircleOutline, trashOutline, trashSharp, warningOutline, warningSharp, idCardOutline, cardOutline, bagAddOutline, statsChartOutline, playCircleOutline, hammerOutline
  } from 'ionicons/icons';

  export default defineComponent({
    name: 'App',
    components: {
      IonApp, 
      IonButton,
      IonContent, 
      IonIcon, 
      IonItem, 
      IonLabel, 
      IonList,  
      IonMenu, 
      IonMenuToggle, 
      IonNote, 
      IonRouterOutlet, 
      IonSplitPane,
    },
    data: function(){
      return {
        user:'',
        company:'',
      }
    },
    methods:{
      logOut() {
        this.$store.commit('setApiToken', '')
        this.$store.commit('setUserId', '')
        this.$store.commit('setUserName', '')
        this.$store.commit('setUserRole', '')
        this.$router.push('/login');
      },
      getUserCompany(){
        this.axios.post(this.apiUrl + 'get-user-company', {}, this.headers)
        .then(async (response) => {
          this.company=response.data.user.company;
        })
      },
    },
    setup() {
      const selectedIndex = ref(0);
      
      const appPages = [
        {
          title: 'Dashboard',
          url: '/folder/Dashboard',
          iosIcon: homeOutline,
          mdIcon: homeOutline
        },
        {
          title: 'Registrazione attività',
          url: '/folder/registrazione-attivita',
          iosIcon: earthOutline,
          mdIcon: earthOutline
        },
        {
          title: 'Report attività',
          url: '/folder/report-attivita',
          iosIcon: barChartOutline,
          mdIcon: barChartOutline
        },
        {
          title: 'Programmazione attività',
          url: '/folder/calendar',
          iosIcon: calendarNumberOutline,
          mdIcon: calendarNumberOutline
        },
        {
          title: 'Report contratti',
          url: '/folder/report-contratti',
          iosIcon: documentTextOutline,
          mdIcon: documentTextOutline
        },
        {
          title: 'Badge Virtuale',
          url: '/folder/badge-virtuale',
          iosIcon: cardOutline,
          mdIcon: cardOutline
        },
        {
          title: 'Report presenze',
          url: '/folder/report-presenze',
          iosIcon: idCardOutline,
          mdIcon: idCardOutline
        },
        {
          title: 'Richiesta permessi',
          url: '/folder/ferie-permessi',
          iosIcon: airplaneOutline,
          mdIcon: airplaneOutline
        },
        {
          title: 'Report permessi',
          url: '/folder/report-permessi',
          iosIcon: fileTrayOutline,
          mdIcon: fileTrayOutline
        },
        {
          title: 'Gestione area personale',
          url: '/folder/area-personale',
          iosIcon: personCircleOutline,
          mdIcon: personCircleOutline
        },
        {
          title: 'Gestione operativa',
          url: '/folder/gestioni',
          iosIcon: cogOutline,
          mdIcon: cogOutline
        },
        {
          title: 'Report idoneità',
          url: '/folder/report-idoneita',
          iosIcon: bagAddOutline,
          mdIcon: bagAddOutline
        },
        {
          title: 'Monitoraggio MES',
          url: '/folder/MonitoraggioMES',
          iosIcon: playCircleOutline,
          mdIcon: playCircleOutline
        },
        {
          title: 'Storico MES',
          url: '/folder/storicoMES',
          iosIcon: statsChartOutline,
          mdIcon: statsChartOutline
        },
        {
          title: 'Gestione MES',
          url: '/folder/GestioneMES',
          iosIcon: hammerOutline,
          mdIcon: hammerOutline
        },
      ];
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    const route = useRoute();

    return { 
      selectedIndex,
      appPages, 
      labels,
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      cogOutline,
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline,
      warningSharp,
      isSelected: (url) => url === route.path ? 'selected' : ''
    }
  },
  beforeCreate: function() {
    this.$store.commit('initialiseStore');
  },
});
</script>

<style scoped>
  ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }

  ion-menu.md ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }

  ion-menu.md ion-list {
    padding: 20px 0;
  }

  ion-menu.md ion-note {
    margin-bottom: 30px;
  }

  ion-menu.md ion-list-header,
  ion-menu.md ion-note {
    padding-left: 10px;
  }

  ion-menu.md ion-list#inbox-list {
    border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
  }

  ion-menu.md ion-list#inbox-list ion-list-header {
    font-size: 22px;
    font-weight: 600;
    min-height: 20px;
  }

  ion-menu.md ion-list#labels-list ion-list-header {
    font-size: 16px;

    margin-bottom: 18px;

    color: #757575;

    min-height: 26px;
  }

  ion-menu.md ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
  }

  ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
  }

  ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }

  ion-menu.md ion-item ion-icon {
    color: #616e7e;
  }

  ion-menu.md ion-item ion-label {
    font-weight: 500;
  }

  ion-menu.ios ion-content {
    --padding-bottom: 20px;
  }

  ion-menu.ios ion-list {
    padding: 20px 0 0 0;
  }

  ion-menu.ios ion-note {
    line-height: 24px;
    margin-bottom: 20px;
  }

  ion-menu.ios ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 50px;
  }

  ion-menu.ios ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }

  ion-menu.ios ion-item ion-icon {
    font-size: 24px;
    color: #73849a;
  }

  ion-menu.ios ion-list#labels-list ion-list-header {
    margin-bottom: 8px;
  }

  ion-menu.ios ion-list-header,
  ion-menu.ios ion-note {
    padding-left: 16px;
    padding-right: 16px;
  }

  ion-menu.ios ion-note {
    margin-bottom: 8px;
  }

  ion-note {
    display: inline-block;
    font-size: 16px;

    color: var(--ion-color-medium-shade);
  }

  ion-item.selected {
    --color: var(--ion-color-primary);
  }

  ion-menu {
  max-width: 350px;
  }

  #logoutDiv {
    margin-top: 20px;
  }
</style>