import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  {
    path: '',
    redirect: '/login'
  },
   {
    path: '/folder/Dashboard',
    component: () => import ('../views/Dashboard.vue')
  },
   {
    path: '/folder/access-log',
    component: () => import ('../views/AccessLog.vue')
  },
  {
    path: '/folder/area-personale',
    component: () => import ('../views/Areautente.vue')
  },
  {
    path: '/folder/:id',
    component: () => import ('../views/Folder.vue')
  },
  {
    path: '/folder/report-presenze',
    component: () => import ('../views/ReportPresenze.vue')
  },
  {
    path: '/login',
    component: () => import ('../views/Login.vue')
  },
  {
    path: '/folder/update-password/:id',
  },
  {
    path: '/folder/dettaglio-presenza',
    component: () => import ('../views/DettaglioPresenza.vue')
  },
  {
    path: '/folder/ferie-permessi',
    component: () => import ('../views/GestionePermessi.vue')
  },  
  {
    path: '/folder/badge-virtuale',
    component: () => import ('../views/BadgeVirtuale.vue')
  },
  //ATTIVITA'
  {
    path: '/folder/registrazione-attivita',
    component: () => import ('../views/RegistraAttivita.vue')
  },
  {
    path: '/folder/dettaglio-attivita/:id',
    component: () => import ('../views/DettaglioAttivita.vue')
  },
  {
    path: '/folder/fine-attivita/:cliente/:id/:ref_off',
    component: () => import ('../views/ChiusuraAttivita.vue')
  },
   {
    path: '/folder/report-attivita/',
    component: () => import ('../views/ReportAttivita.vue')
  },
  {
    path: '/folder/report-permessi/',
    component: () => import ('../views/ReportPermessi.vue')
  },
  {
    path: '/folder/report-contratti',
    component: () => import ('../views/ReportContratti.vue')
  },
  {
    path: '/folder/dettaglio-contratto/:id/:ref_off',
    component: () => import ('../views/DettaglioContratto.vue')
  },
  {
    path: '/folder/dettaglio-permesso/:id',
    component: () => import ('../views/DettaglioPermesso.vue')
  },
   {
    path: '/folder/calendar',
    component: () => import ('../views/Calendar.vue')
  },
  //GESTIONE
  {
    path: '/folder/gestioni',
    component: () => import ('../views/Gestioni.vue')
  },
  //COMPANIES
  {
    path: '/folder/company',
    component: () => import ('../views/Company.vue')
  },
  {
    path: '/folder/companies-list',
    component: () => import ('../views/CompaniesList.vue')
  },
  {
    path: '/folder/company-modifica/:id',
    component: () => import ('../views/ModificaCompany.vue')
  },
  {
    path: '/folder/company-elimina/:id',
    component: () => import ('../views/CompaniesList.vue')
  },
   //TASK PROJECTS
   {
    path: '/folder/taskproject',
    component: () => import ('../views/TaskProject.vue')
  },
  {
    path: '/folder/task-projects-list',
    component: () => import ('../views/TaskProjectList.vue')
  },
  {
    path: '/folder/task-project-modifica/:id',
    component: () => import ('../views/ModificaTaskProject.vue')
  },
  {
    path: '/folder/task-project-elimina/:id',
    component: () => import ('../views/TaskProjectList.vue')
  },
  //COST
  {
    path: '/folder/cost',
    component: () => import ('../views/Cost.vue')
  },
  {
    path: '/folder/costs-list',
    component: () => import ('../views/CostsList.vue')
  },
  {
    path: '/folder/cost-modifica/:id',
    component: () => import ('../views/ModificaCost.vue')
  },
  {
    path: '/folder/cost-elimina/:id',
    component: () => import ('../views/CostsList.vue')
  },
  //ACCOUNT
  {
    path: '/folder/accounts',
    component: () => import ('../views/Account.vue')
  },
  {
    path: '/folder/accounts-list',
    component: () => import ('../views/AccountsList.vue')
  },
  {
    path: '/folder/account-modifica/:id',
    component: () => import ('../views/ModificaAccount.vue')
  },
  {
    path: '/folder/account-elimina/:id',
    component: () => import ('../views/AccountsList.vue')
  },
  //USERS
  {
    path: '/folder/user',
    component: () => import ('../views/User.vue')
  },
  {
    path: '/folder/users-list',
    component: () => import ('../views/UsersList.vue')
  },
  {
    path: '/folder/user-modifica/:id',
    component: () => import ('../views/ModificaUser.vue')
  },
  {
    path: '/folder/user-elimina/:id',
    component: () => import ('../views/UsersList.vue')
  },
  {
    path: '/folder/idoneity',
    component: () => import ('../views/Idoneita.vue')
  },
  {
    path: '/folder/report-idoneita',
    component: () => import ('../views/ReportIdoneita.vue')
  },
  //MES
  {
    path: '/folder/monitoraggioMES',
    component: () => import ('../views/MES/MonitoraggioMES.vue')
  },
  {
    path: '/folder/storicoMES',
    component: () => import ('../views/MES/storicoMES.vue')
  },
   {
    path: '/folder/GestioneMES',
    component: () => import ('../views/MES/GestioneMes.vue')
  },
   {
    path: '/folder/DispositivoMes/:id',
    component: () => import ('../views/MES/DettDispMes.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
