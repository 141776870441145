import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createStore } from 'vuex'

import { IonicVue } from '@ionic/vue';
import { useRouter } from 'vue-router';

import axios from 'axios';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';


const store = createStore({
  state() {
    return {
      apiToken: '',
      userId: '',
      userName: '',
      userRole:'',
    }
  },
  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
      }
    },
    setApiToken(state, value) {
      state.apiToken = value
    },
    setUserId(state, value) {
      state.userId = value
    },
    setUserName(state, value) {
      state.userName = value
    },
    setUserRole(state, value) {
      state.userRole = value
    },
  }
})
store.subscribe((mutation, state) => {
  localStorage.setItem('store', JSON.stringify(state));
});

const app = createApp(App)
  .use(IonicVue)
  .use(router);

app.use(store)

// MIXIN VALIDATION API_TOKEN - LOGIN
app.mixin({
  created: function () {
    if (!this.$store.state.apiToken || !this.$store.state.userId) {
      const router = useRouter();
      if (router.currentRoute.value.path != '/login') {
        window.location.href = '/login'
      }
    }
  },
})

// CONFIG AXIOS
app.config.globalProperties.axios = axios
//app.config.globalProperties.apiUrl = 'http://abj.it/api/'
app.config.globalProperties.apiUrl = 'http://backend.hbj-group.com/api/'
app.mixin({
  data: function () {
    return {
      headers: {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.apiToken,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }
    }
  },
})

router.isReady().then(() => {
  app.mount('#app');
});